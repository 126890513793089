import React from 'react';

import BlogSection from '../section-blog/blog-section';
import BlogSummaryItem from '../section-blog/blog-summary-item';

const BlogPosts = ({ posts }) => {
  return (
    <BlogSection title="All Blog Posts">
      {posts.map((post) => (
        <BlogSummaryItem
          post={post.node.frontmatter}
          key={post.node.fields.slug}
          name={post.node.frontmatter.title}
          description={post.node.frontmatter.description}
          link={post.node.fields.slug}
          internal
        />
      ))}
    </BlogSection>
  );
};

export default BlogPosts;
